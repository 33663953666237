<template>
    <HeaderSection v-bind="headerProps" />
    <SocialBox v-bind="socialProps" />
    <section class="hero-media">
      <Carousel :items="mediaInfo" />
    </section>
    <ContentSection v-bind="contentInfo" />
    <BookSection v-bind="bookProps" />
</template>

<script>
import ContentSection from '../../organisms/ContentSection/ContentSection.vue';
import HeaderSection from '../../organisms/Header/Header.vue';
import SocialBox from "../../molecules/SocialBox/SocialBox.vue";
import BookSection from '../../organisms/BookingForm/BookingForm.vue';
import Carousel from '../../organisms/Carousel/Carousel.vue';

export default {
  components: {
    ContentSection, HeaderSection, SocialBox, BookSection, Carousel
  },
  props: {
    headerProps: {
        type: Object,
        default: () => null
    },
    socialProps: {
        type: Object,
        default: () => null
    },
    bookProps: {
        type: Object,
        default: () => null
    },
    mediaInfo: {
        type: Object,
        default: () => null
    },
    contentInfo: {
      type: Object,
      default: () => null
    },
  }
}
</script>

<style scoped>

</style>