<template>
  <AboutTemplate
    :headerProps="headerProps"
    :socialProps="socialProps"
    :mediaInfo="mediaInfo"
    :contentInfo="contentInfo"
    :bookProps="bookProps"
  />
</template>

<script>
import AboutTemplate from "@/components/templates/About/About";
import { headerProps, socialProps } from "@/components/constants.js";
import heroImg from "@/assets/images/sample-hero.jpg";
import AboutImg from "@/assets/images/restaurant.jpg";
import moment from 'moment'
export default {
  components: {
    AboutTemplate,
  },
  data() {
    return {
      headerProps: { ...headerProps, currentRoute: this.$route.name },
      socialProps: socialProps,
      bookProps: {
        checkInDate: moment().add(1, 'days').set('hour', 13),
        checkOutDate: new Date("2018-11-21"),
      },
      mediaInfo: [
        {
          image: AboutImg,
        },
      ],
      contentInfo: {
        title: "About Starlight",
        media: {
          img: AboutImg,
        },
        content: `
            <p>Welcome to Starlight Ballroom, where dreams come to life and celebrations reach new heights. With our exceptional venue and impeccable service, we aim to create unforgettable moments for you and your guests. As a premier banquet hall rental, we take pride in offering a luxurious and versatile space that caters to all your event needs. Whether you're planning a wedding, corporate gathering, or social soirée, our dedicated team is committed to ensuring every detail is executed flawlessly.</p>
            <p>Our banquet hall is a masterpiece of elegance and sophistication, designed to inspire and captivate. The moment you step through our doors, you'll be greeted by a grand foyer that exudes a sense of grandeur and sets the tone for an extraordinary experience. Our spacious main hall features stunning architectural details, including soaring ceilings, crystal chandeliers, and tasteful decor, creating a truly enchanting ambiance. With flexible seating arrangements and state-of-the-art audiovisual capabilities, we can accommodate both intimate gatherings and larger celebrations with ease.</p>
            <p>At Starlight Ballroom, we understand that every event is unique and should reflect your personal style and vision. Our team of experienced event planners will work closely with you to bring your ideas to life and ensure that your event surpasses all expectations. From conceptualization to execution, we offer comprehensive event management services, assisting you in selecting the perfect theme, coordinating vendors, and attending to every intricate detail. With our unwavering commitment to excellence, you can relax and enjoy your event while we take care of the rest.</p>
            <p>We believe that exceptional service is the key to creating a truly memorable event. Our professional and attentive staff is dedicated to providing top-notch hospitality, ensuring that you and your guests are treated with the utmost care and respect. From the moment you arrive until the last guest departs, our team will be on hand to anticipate your needs and deliver a seamless experience. Whether it's providing exquisite cuisine, coordinating flawless logistics, or offering personalized assistance, we strive to exceed your expectations and make your event an extraordinary affair.</p>
            <p>Come and experience the epitome of elegance and sophistication at Starlight Ballroom. Let us be your partner in creating cherished memories that will last a lifetime. Contact us today to schedule a tour of our exceptional venue and discover why we are the preferred choice for discerning clients seeking an unforgettable event space.</p>
        `,
      },
    };
  },
};
</script>

<style scoped></style>
