<template>
  <div class="content-wrap clearfix">
    <div class="entry entry-page">
      <h2 class="entry-title" v-html="title"></h2>
      <div class="entry-content">
        <div class="grid-container">
          <div class="entry-media grid-column" v-if="media">
            <a :href="media.img" data-fancybox="gallery"><img :src="media.img" alt="">
            </a>
          </div>
          <div class="grid-column order-first" v-html="content"></div>
        </div>
      </div>
    </div>
    <CopyRight v-bind="footerProps" class="mb-28"/>
  </div>
</template>

<script>
import CopyRight from '../../molecules/CopyRight/CopyRight.vue';
import { footerProps } from '../../constants';
export default {
  components: {
    CopyRight
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    media: {
      type: Object,
      default: () => null
    },
    content: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      footerProps
    }
  }
};
</script>

<style></style>
