<template>
    <div class="booking-form fixed bottom-5 md:bottom-0 top-auto">
        <div class="grid grid-cols-1 sm:grid-cols-3">
            <div class="col-span-2 flex flex-col justify-center flex-2" style="background-color: #eeeeee;">
                <div class="flex px-4 space-x-2">
                    <div class="field field-date field-checkin py-0 flex-1 pt-2">
                        <label class="text-black">Event Start Date</label>
                        <DatePicker v-model="checkInAt" :min-date="new Date()">
                            <template #default="{ togglePopover }">
                                <div
                                    class="bg-white text-black border-gray w-full h-11 px-4 py-1.5 flex items-center justify-between" style="border: 3px solid rgba(0, 0, 0, .05)"
                                    @click="togglePopover"
                                >
                                    {{ moment(checkInAt).format('MMMM D, YYYY') }}
                                    <Icon name="CalendarIcon" size="5" isOutline color="black"/>
                                </div>
                            </template>
                        </DatePicker>
                    </div>
                    <div class="field field-date field-checkout py-0 flex-1 pt-2">
                        <label class="text-black">Number of Days</label>
                        <Select v-model:selectedItem="day" label="" :items="days" placeholder="Select" customSelectClass="mb-5" />
                    </div>
                </div>
                <!-- <div class="cursor-pointer ml-5 text-blue-700 -mt-1 text-center" @click="$router.push({name: 'Packages'})">Take a look at our special package deals</div> -->
            </div>
            <a @click="onSubmit" class="button-color bg-black text-white flex-1 border-black h-full flex justify-center items-center py-5">CHECK AVAILABILITY</a>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import { DatePicker } from 'v-calendar';
import 'v-calendar/dist/style.css';
import Icon from '../../atoms/Icons/Icons.vue'
import Select from '../../molecules/Selects/SimpleNative/SimpleNative.vue';
import { Settings } from "../../../../settings.js";

/**
 * - Use it to show a simple notification
 */
export default {
    components: {
        DatePicker,
        Icon,
        Select
    },
    props: {
        checkInDate: {
            type: Date,
            default: ''
        },
        checkOutDate: {
            type: Date,
            default: ''
        },
    },
    data() {
        return {
            checkInAt: this.checkInDate,
            day: 1,
            moment,
            days: [
            ],
        }
    },
    methods: {
        async onSubmit() {
            const endDate = moment(this.checkInAt).add('days', this.day).format('YYYY-MM-DD')
            const res = await this.actions.inventory.checkInventory({
                siteId: Settings.siteId,
                startDate: new Date(this.checkInAt),
                endDate: new Date(endDate),
            });
            if (res.checkInventory.isAvailable) {
                const endDate = moment(this.checkInAt).add('days', this.day).format('YYYY-MM-DD')
                this.$router.push({ name: 'Booking Result', params: {}, query: {startDate: moment(this.checkInAt).format('YYYY-MM-DD'), endDate}});
            }
        },
        dateToString(date) {
            const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            const monthShorts = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            return {
                short: date.getDate() + ' ' + monthShorts[date.getMonth()] + ' ' + (date.getFullYear() + '').slice(-2),
                long: date.getDate() + ' ' + months[date.getMonth()] + ' ' + date.getFullYear(),
            }
        },
    },
    mounted() {
        for (let i = 0; i < 10; i++) {
            this.days.push({text: i + 1, value: i + 1})
        }
    }
}
</script>

<style>
.button-color {
    min-width: 150px;
}

.py-2 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.flex-2 {
    flex: 2;
}
</style>